import { Link, useNavigate } from "react-router-dom";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import SwiperCore, { Autoplay } from "swiper/core";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";

function Home() {
    SwiperCore.use([Autoplay]);
    const swiperParams = {
        autoplay: {
            delay: 3000,
            disableOnInteraction: false
        }
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const navigate = useNavigate();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
        axios.get(`https://xoso188.net/api/front/open/lottery/history/list/5/miba`).then((res) => {
            setBet(res.data.t);
            if (bet != res.data.t) {
                setBet(res.data.t);
                localStorage.setItem("xsmb", JSON.stringify(res.data.t));
            }
        });
    }, []);
    const date0 = new Date();
    const currentHour = date0.getHours();
    const [dateMT, setDateMT] = useState(date0.getDay());
    const [dataMT, setDataMT] = useState();
    const [imageMT, setImageMT] = useState([]);
    const handleChangeMT = (e) => {
        setDateMT(e.target.value);
    };
    const [dateMN, setDateMN] = useState(date0.getDay());
    const [dataMN, setDataMN] = useState([]);
    const [imageMN, setImageMN] = useState([]);
    const handleChangeMN = (e) => {
        setDateMN(e.target.value);
    };
    useEffect(() => {
        if (dateMT == 0) {
            if (currentHour < 18) {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            } else {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            }
        }
        if (dateMT == 1) {
            if (currentHour < 18) {
                setDataMT(["phye", "thth"]);
                setImageMT(["phuyen", "hue"]);
            } else {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            }
        }
        if (dateMT == 2) {
            if (currentHour < 18) {
                setDataMT(["dalak", "quna"]);
                setImageMT(["daklak", "quangnam"]);
            } else {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            }
        }
        if (dateMT == 3) {
            if (currentHour < 18) {
                setDataMT(["dana", "khho"]);
                setImageMT(["danang", "khanhhoa"]);
            } else {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            }
        }
        if (dateMT == 4) {
            if (currentHour < 18) {
                setDataMT(["qubi", "bidi", "qutr"]);
                setImageMT(["quangbinh", "binhdinh", "quangtri"]);
            } else {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            }
        }
        if (dateMT == 5) {
            if (currentHour < 18) {
                setDataMT(["gila", "nith"]);
                setImageMT(["gialai", "ninhthuan"]);
            } else {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            }
        }
        if (dateMT == 6) {
            if (currentHour < 18) {
                setDataMT(["dana", "qung", "dano"]);
                setImageMT(["danang", "quangngai", "daknong"]);
            } else {
                setDataMT(["khho", "kotu", "thth"]);
                setImageMT(["khanhhoa", "komtum", "hue"]);
            }
        }
    }, [dateMT]);
    useEffect(() => {
        if (dateMN == 0) {
            if (currentHour < 17) {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            } else {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            }
        }
        if (dateMN == 1) {
            if (currentHour < 17) {
                setDataMN(["cama", "doth", "tphc"]);
                setImageMN(["camau", "dongthap", "tphcm"]);
            } else {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            }
        }
        if (dateMN == 2) {
            if (currentHour < 17) {
                setDataMN(["bali", "vuta", "betr"]);
                setImageMN(["baclieu", "vungtau", "bentre"]);
            } else {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            }
        }
        if (dateMN == 3) {
            if (currentHour < 17) {
                setDataMN(["cath", "dona", "sotr"]);
                setImageMN(["cantho", "dongnai", "soctrang"]);
            } else {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            }
        }
        if (dateMN == 4) {
            if (currentHour < 17) {
                setDataMN(["angi", "bith", "tani"]);
                setImageMN(["angiang", "binhthuan", "tayninh"]);
            } else {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            }
        }
        if (dateMN == 5) {
            if (currentHour < 17) {
                setDataMN(["bidu", "trvi", "vilo"]);
                setImageMN(["binhduong", "travinh", "vinhlong"]);
            } else {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            }
        }
        if (dateMN == 6) {
            if (currentHour < 17) {
                setDataMN(["biph", "hagi", "loan", "tphc"]);
                setImageMN(["binhphuoc", "haugiang", "longan", "tphcm"]);
            } else {
                setDataMN(["dalat", "kigi", "tigi"]);
                setImageMN(["dalat", "kiengiang", "tiengiang"]);
            }
        }
    }, [dateMN]);

    function isMobileDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function launchGame(params) {
        const form = {
            ...params,
            mobile: isMobileDevice == true ? 1 : 0
        };
        axios
            .post(`${process.env.REACT_APP_API_URL}/casino/launch`, form)
            .then((res) => {
                if (res.data.data.errMsg == "SUCCESS") {
                    window.open(res.data.data.gameUrl, '_blank');
                } else {
                    swal("Sảnh đang bảo trì", "Vui lòng chọn sảnh khác", "error");
                }
            })
            .catch((err) => swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã có lỗi xảy ra", "error"));
    }
    return (
        <>
            {swiperParams && notify && (
                <div className="bg-black container" style={{ maxWidth: "1350px" }}>
                    <div className="box-slide" style={{ overflow: "hidden", maxHeight: "450px" }}>
                        <Swiper {...swiperParams}>
                            {notify.map((item, index) => (
                                <>
                                    {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                        <SwiperSlide>
                                            <img alt={item.title.replace("}", "").replace("{", "")} src={item.image} />
                                        </SwiperSlide>
                                    ) : null}
                                </>
                            ))}
                        </Swiper>
                    </div>
                    <div className="container" style={{ maxWidth: "800px", margin: "30px auto 0" }}>
                        <div className="box-miba">
                            <div className="box-miba-left" onClick={() => navigate("/xsmb/lo2")}>
                                <img alt="" src={require("../../images/xs3mien/mienbac.png")} style={{ maxWidth: "200px" }} />
                            </div>
                            <div className="box-miba-right">
                                <div className="box-text">Miền Bắc</div>
                                <div className="box-text">Ngày: {bet?.issueList[0]?.turnNum}</div>
                                <div style={{ display: "flex" }}>
                                    <div className="box-miba-kq" style={{ width: "60%" }}>
                                        {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                            <div className="ball">
                                                <img alt="" src={`/images/ball/${x}.png`} />
                                            </div>
                                        ))}
                                    </div>
                                    <div className="dat-cuoc" onClick={() => navigate("/xsmb/lo2")} style={{ width: "25%", marginLeft: "15%" }}>
                                        Đặt cược
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr", justifyItems: "center" }} className="container">
                        <div>
                            <div className="section-title2" onClick={() => navigate("/lottery")}>
                                Xổ số nhanh
                            </div>
                            <div className="list-game-xs" style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
                                <Link to="/xs75s/lo2">
                                    <img alt="" src={require(`../../images/mb75s.png`)} className="img-xs" />
                                </Link>
                                <Link to="/xs120s/lo2">
                                    <img alt="" src={require(`../../images/st2p.png`)} className="img-xs" />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="section-title" onClick={() => navigate("/lottery")}>
                        Miền Nam
                    </div>
                    <div className="list-game-xs">
                        {dataMN?.map((item, index) => (
                            <>
                                <Link to={`/xsmn/lo2/${item}`} key={item}>
                                    <img alt="" src={require(`../../images/xs3mien/${imageMN[index]}.png`)} className="img-xs" />
                                </Link>
                            </>
                        ))}
                    </div>
                    <div className="section-title" onClick={() => navigate("/lottery")}>
                        Miền Trung
                    </div>
                    <div className="list-game-xs">
                        {dataMT?.map((item, index) => (
                            <>
                                <Link to={`/xsmt/lo2/${item}`} key={item}>
                                    <img alt="" src={require(`../../images/xs3mien/${imageMT[index]}.png`)} className="img-xs" />
                                </Link>
                            </>
                        ))}
                    </div>
                </div>
            )}
            {/*<section className="section-app">
                <div className="container">
                    <div className="d-flexs">
                        <div className="prize-wrap">
                            <div className="prize-box">
                                <div className="prize-title">DANH SÁCH TRÚNG THƯỞNG</div>
                                <div className="prize-header">
                                    <span>Người chơi</span>
                                    <span>Trò chơi</span>
                                    <span>Phần thưởng</span>
                                </div>
                                <div className="prize-board">
                                    <marquee onmouseout="this.start()" onmouseover="this.stop()" direction="up" behavior="scroll" loop="loop" scrollamount="5">
                                        <ul className="br_wiboard_ul">
                                            <li className="br_wiboard_uli">
                                                <span className="br_wiboard_ulcustomerName">*******yen</span>
                                                <span className="br_wiboard_ulgameName">Bóng đá</span>
                                                <span className="br_wiboard_ulwinAmount">16,182</span>
                                            </li>
                                            <li className="br_wiboard_uli">
                                                <span className="br_wiboard_ulcustomerName">*******yen</span>
                                                <span className="br_wiboard_ulgameName">Bóng đá</span>
                                                <span className="br_wiboard_ulwinAmount">16,182</span>
                                            </li>
                                            <li className="br_wiboard_uli">
                                                <span className="br_wiboard_ulcustomerName">*******yen</span>
                                                <span className="br_wiboard_ulgameName">Bóng đá</span>
                                                <span className="br_wiboard_ulwinAmount">16,182</span>
                                            </li>
                                        </ul>
                                    </marquee>
                                </div>
                            </div>
                        </div>
                        <div className="qrcode-wrap" style={{ width: "100%" }}>
                            <div className="qrcode-box">
                                <div className="qrcode_text_one">
                                    <p className="text_scan">Quét Mã</p>
                                    <p>Để tải xuống ứng dụng</p>
                                </div>
                                <div className="down_info">
                                    <div className="down_icon" style={{ display: "flex", justifyContent: "space-around" }}>
                                        <span className={`down_icon_andr active`}>
                                            <svg className="svg-icon">
                                                <use xlinkHref="#icon-qrAndroid"></use>
                                            </svg>
                                        </span>
                                        <span className={`down_icon_ios active`}>
                                            <svg className="svg-icon">
                                                <use xlinkHref="#icon-qrIos"></use>
                                            </svg>
                                        </span>
                                    </div>
                                    <div className="qrcode" style={{ display: "flex", justifyContent: "space-around" }}>
                                        <img src={require("../../image/qr.jpg")} />
                                        <img src={require("../../image/qr.jpg")} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-game">
                <div className="container">
                    <div className="br_bottom_gamelink">
                        <ul>
                            <li className="live" onClick={() => navigate("/casino")}>
                                <i className="icon live"></i>
                                <span className="title">CASINO TRỰC TUYẾN</span>
                                <span className="subTitle">LIVE CASINO</span>
                            </li>
                            <li className="electron" onClick={() => navigate("/slots")}>
                                <i className="icon electron"></i>
                                <span className="title">NỔ HŨ</span>
                                <span className="subTitle">CASINO</span>
                            </li>
                            <li className="sports" onClick={() => navigate("/sports")}>
                                <i className="icon sports"></i>
                                <span className="title">CÁ CƯỢC THỂ THAO</span>
                                <span className="subTitle">SPORT BETTING</span>
                            </li>
                            <li className="lott" onClick={() => navigate("/lottery")}>
                                <i className="icon lott"></i>
                                <span className="title">XỔ SỐ</span>
                                <span className="subTitle">LOTTERY</span>
                            </li>
                        </ul>
                    </div>
                    <div className="br_bottom_ad"></div>
                </div>
            </section>*/}
        </>
    );
}
export default Home;
