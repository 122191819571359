import axios from "axios";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import Results from "./0_Results";
import History from "../../components/SidebarHistory";
import CountDown from "./0_countdown";
import TabNavigation from "./0_Tab";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useNavigate } from "react-router-dom";

function Xoso() {
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/lottery");
    };
    const [bet, setBet] = useState(JSON.parse(localStorage.getItem("xsmb")));
    const [loadGame, setLoadGame] = useState(false);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [total, setTotal] = useState(null);
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const [choose, setChoose] = useState([]);
    const [textArea, setTextArea] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios.get(`https://xoso188.net/api/front/open/lottery/history/list/5/miba`).then((res) => {
            setBet(res.data.t);
            if (bet != res.data.t) {
                setBet(res.data.t);
                localStorage.setItem("xsmb", JSON.stringify(res.data.t));
            }
            setTotal([
                {
                    dacbiet: JSON.parse(res.data.t.issueList[0].detail)[0],
                    nhat: JSON.parse(res.data.t.issueList[0].detail)[1],
                    hai: JSON.parse(res.data.t.issueList[0].detail)[2].split(",").join(" "),
                    ba: JSON.parse(res.data.t.issueList[0].detail)[3].split(",").join(" "),
                    tu: JSON.parse(res.data.t.issueList[0].detail)[4].split(",").join(" "),
                    nam: JSON.parse(res.data.t.issueList[0].detail)[5].split(",").join(" "),
                    sau: JSON.parse(res.data.t.issueList[0].detail)[6].split(",").join(" "),
                    bay: JSON.parse(res.data.t.issueList[0].detail)[7].split(",").join(" ")
                }
            ]);
        });
        axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
            if (profile != res.data.data) {
                setProfile(res.data.data);
                localStorage.setItem("profile", JSON.stringify(res.data.data));
            }
        });
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const openPopup1 = () => {
        setIsOpen1(true);
    };
    const closePopup1 = () => {
        setIsOpen1(false);
    };

    const [isOpen2, setIsOpen2] = useState(false);
    const openPopup2 = () => {
        setIsOpen2(true);
    };
    const closePopup2 = () => {
        setIsOpen2(false);
    };
    const fTextarea = (e) => {
        setTextArea(e.target.value.trim().replace(/[^0-9., ]/g, ""));
        const text = e.target.value.trim().replace(/[^0-9., ]/g, "");
        let splitChar;
        if (text.indexOf(" ") !== -1) {
            splitChar = " ";
        } else if (text.indexOf(".") !== -1) {
            splitChar = ".";
        } else if (text.indexOf(",") !== -1) {
            splitChar = ",";
        } else {
            setChoose([text]);
            return;
        }
        const arr = text.split(splitChar);
        const uniqueArr = arr.filter((item, index) => arr.indexOf(item) === index);
        setChoose(uniqueArr);
        setTextArea(uniqueArr.join(","));
    };
    const onChoose = (e) => {
        if (choose.includes(e.target.id)) {
            setChoose(choose.filter((item) => item !== e.target.id));
        } else if (choose.length < 8) {
            setChoose([...choose, e.target.id]);
        } else {
            swal("Chú ý", "Bạn chỉ được chọn tối đa 8 số", "warning");
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (loadGame == true) return;
        setLoadGame(true);
        const newData = [];
        let notAllowed = false;
        choose.map((item) => {
            if (item.length == 1) {
                newData.push("0" + Number(item).toString().toLowerCase());
            } else if (item.length > 2) {
                notAllowed = true;
            } else if (item.length == 2) {
                newData.push(item);
            }
        });
        if (notAllowed) {
            swal("Thông báo", "Danh sách số đánh có số không hợp lệ.", "error");
            return;
        }
        if (newMoney == 0 || newMoney == null) {
            swal("Thất bại", "Bạn chưa nhập tiền", "error");
            return;
        }
        if (newMoney * 1000 > profile.money) {
            swal("Thất bại", "Số dư không đủ", "error");
            return;
        }
        if (newMoney * 1000 < setting?.mincuoc) {
            swal("Chú ý", `Số tiền cược tối thiểu là ${Number(setting?.mincuoc).toLocaleString('vi-VN')}`, "warning");
            return;
        }
        if (newMoney * 1000 > 300000) {
            swal("Chú ý", `Số tiền cược tối đa là 300.000`, "warning");
            return;
        }
        if (!newData || choose.length == 0) {
            swal("Thất bại", "Bạn chưa chọn số đánh", "info");
            return;
        }
        if (choose.length != 8) {
            swal("Chú ý", "Vui lòng chọn đủ 8 số", "warning");
            return;
        }
        const currentDate = new Date();
        const minute = currentDate.getMinutes() < 10 ? "0" + currentDate.getMinutes() : currentDate.getMinutes();
        if (
            Number(currentDate.getHours() + "" + minute) >= 1810 &&
            Number(currentDate.getHours() + "" + minute) < 1850 
        ) {
            swal("Đặt cược không thành công.", " Đã hết thời gian cược", "warning");
            return;
        } else {
            let formData = {
                state: newData.join(" "),
                id: bet.turnNum,
                type: 8,
                money: newMoney * 1000
            };
            if (Number(currentDate.getHours() + "" + minute) > 1850) {
                const date = new Date();
                const day = Number(date.getDate() + 1) < 10 ? "0" + Number(date.getDate() + 1) : Number(date.getDate() + 1);
                const month = date.getUTCMonth() < 9 ? "0" + Number(date.getUTCMonth() + 1) : Number(date.getUTCMonth() + 1);
                formData = {
                    state: newData.join(" "),
                    id: day + "/" + month + "/" + date.getFullYear(),
                    type: 8,
                    money: newMoney * 1000
                };
            }

            axios
                .post(`${process.env.REACT_APP_API_URL}/history/chooseXSMB`, formData)
                .then((res) => {
                    swal("Đặt cược thành công", "", "success").then(() => setLoadGame(false));
                    setChoose([]);
                    setTextArea("");
                    axios.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {}).then((res) => {
                        if (profile != res.data.data) {
                            setProfile(res.data.data);
                            localStorage.setItem("profile", JSON.stringify(res.data.data));
                        }
                    });
                })
                .catch((err) =>
                    swal("Thất bại", err.response.data.message != "internal server error" ? err.response.data.message : "Đã xảy ra lỗi", "error").then(() => setLoadGame(false))
                );
        }
    };
    const [newMoney, setNewMoney] = useState(1);
    const numbers = Array.from(Array(100).keys());
    const date0 = new Date();
    const [date, setDate] = useState(date0.getDay());
    const [activeOption, setActiveOption] = useState("1");
    const handleOptionClick = (option) => {
        setActiveOption(option);
    };
    const [activeOption2, setActiveOption2] = useState("1");
    const handleOptionClick2 = (option) => {
        setActiveOption2(option);
    };
    return (
        <>
            
            {!bet ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            {bet && (
                <>
                    <div className="container">
                        <div className="game-box-left"><TabNavigation /></div>
                        <div className="game-box-center">
                        <div className="game-detail">
                            <img alt="" src={require(`../../../images/xs3mien/mienbac.png`)} />
                            <div>
                                <div className="game-detail-title">Miền Bắc</div>
                                <div className="game-detail-des">{date === 0 ? "Chủ nhật" : "Thứ " + (date + 1)}</div>
                                <div className="game-detail-des">Lượt xổ: {bet.turnNum}</div>
                                <CountDown date={bet?.turnNum} />
                            </div>
                            <div className="game-result" onClick={openPopup1}>
                                <div className="game-result-left">
                                    <img src="/images/icon_time_gold.png" alt="" /> <span>Kỳ {bet?.issueList[0]?.turnNum}, giải đặc biệt</span>
                                </div>
                                <div className="game-result-center">
                                    {bet?.issueList[0]?.openNum.split(",").map((x) => (
                                        <div className="ball">
                                            <img alt="" src={`/images/ball/${x}.png`} />
                                        </div>
                                    ))}
                                </div>
                                <div className="game-result-right">
                                    <KeyboardArrowDownIcon sx={{ fontSize: "30px" }} />
                                </div>
                            </div>
                        </div>
                        <div style={{ position: "relative" }}>
                            <div className="game-rate">
                                <div><TabNavigation /></div>
                                <div>{setting && "Tỷ lệ cược: 1 ăn " + setting.mb_truotxien8}</div>
                            </div>
                            
                        </div>
                        <div className="main-game">
                            <ul className="tab-navigation tab-game">
                                <li className={activeOption === "1" ? "active" : ""} onClick={() => handleOptionClick("1")}>
                                    Chọn số nhanh
                                </li>
                                <li className={activeOption === "2" ? "active" : ""} onClick={() => handleOptionClick("2")}>
                                    Nhập số
                                </li>
                            </ul>
                            <div className="main_game">
                                {activeOption === "1" && (
                                    <div className="text_choose_center">
                                        <div className="state_choose">
                                            {numbers.map((number) => (
                                                <div
                                                    key={number}
                                                    id={number < 10 ? `0${number}` : number}
                                                    onClick={onChoose}
                                                    className={`choose_xs  ${choose.includes(String(number < 10 ? `0${number}` : number)) ? "chooseItem" : ""}`}>
                                                    {number < 10 ? `0${number}` : number}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                {activeOption === "2" && (
                                    <div className="text_choose_center">
                                        <div className="cach-choi">
                                            Cách chơi:
                                            <br />
                                            Giữa mỗi cược cần phân cách bởi dấu , hoặc khoảng trống. Ví dụ: 10,20,30 hoặc 10 20 30
                                        </div>
                                        <textarea onChange={fTextarea} value={textArea}></textarea>
                                    </div>
                                )}
                            </div>
                        </div>
                        </div><div className="game-box-right footer-game">
                            <ul className="tab-navigation tab-sidebar">
                                <li className={activeOption2 === "1" ? "active" : ""} onClick={() => handleOptionClick2("1")}>
                                    Bảng chọn
                                </li>
                                <li className={activeOption2 === "2" ? "active" : ""} onClick={() => handleOptionClick2("2")}>
                                    Lịch sử
                                </li>
                            </ul>
                            {activeOption2 === "1" && (
                            <form onSubmit={onSubmit}>
                                {choose.length > 0 && (
                                    <div className="footer-selected">
                                        <div>Đã chọn:</div>
                                        <div>{choose.join(", ")}</div>
                                    </div>
                                )}
                                <div className="footer-game-top">
                                    <div className="footer-chip">
                                        <div className="chip-1x" onClick={() => setNewMoney(Number(newMoney) + 1)}></div>
                                        <div className="chip-3x" onClick={() => setNewMoney(Number(newMoney) + 3)}></div>
                                        <div className="chip-5x" onClick={() => setNewMoney(Number(newMoney + 5))}></div>
                                        <div className="chip-10x" onClick={() => setNewMoney(Number(newMoney) + 10)}></div>
                                    </div>
                                    <div>
                                        <input value={newMoney} onChange={(e) => setNewMoney(e.target.value)} min="1" name="money" type="number" />
                                    </div>
                                    <div>
                                        Đã chọn: <b style={{ margin: "0 0.15rem" }}>{choose.length}</b> lô
                                    </div>
                                </div>
                                <div className="footer-game-bottom">
                                    <div className="footer-game-money">
                                        <span>Số tiền:</span> <b>{choose.length != 0 && newMoney ? (newMoney * 1000).toLocaleString('vi-VN') : 0}</b>
                                        <br />
                                        Số dư: {Math.floor(profile?.money).toLocaleString('vi-VN')}
                                    </div>
                                    <button
                                        type="reset"
                                        className="btn-reset"
                                        style={{ opacity: "0.8" }}
                                        onClick={() => {
                                            setNewMoney(1);
                                            setChoose([]);
                                            setTextArea("");
                                        }}>
                                        Cài lại
                                    </button>
                                    <button type="submit" className="btn-sbmit">
                                        Đặt cược
                                    </button>
                                </div>
                            </form>
                            )}
                            {activeOption2 === "2" && <History isOpen={true}/>}
                        </div>
                    </div>
                </>
            )}

            <Results isOpen={isOpen1} total={total} closePopup={closePopup1} date={bet?.issueList[0]?.turnNum} />

            
        </>
    );
}
export default Xoso;
